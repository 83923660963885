import { ComboBox, DatePicker, DayOfWeek, IComboBoxOption, Icon, IDatePickerStrings, TextField } from 'office-ui-fabric-react';
import { MessageBarType } from 'office-ui-fabric-react/lib/components/MessageBar';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { RouteComponentProps } from 'react-router';
import 'react-tabs/style/react-tabs.scss';
import { Constants } from '../../../common/const/constants';
import { CustomSelect, ISelectOption } from '../../../common/customSelect/customSelect';
import { MessageBarComponent } from '../../../common/messageBar/messageBarComponent';
import { SearchPeople } from '../../../common/search/searchPeople';
import { SpinnerContainer } from '../../../common/spinner';
import { UnauthorizedView } from '../../../common/unauthorizedView/unauthorizedView';
import {
	BaseType,
	createEmptyDeed,
	Deed,
	DeedType,
	DeedTypeSubType,
	DocumentFile,
	KeyValue,
	Language,
	NotaryData,
	NotaryPeople,
	Person,
	SearchSelectModel,
	TradeCompany,
	User,
	UserState
} from '../../../model';
import { ActiveLanguage } from '../../../model/enums/activeLanguage';
import { DeedFilter } from '../../../model/filters/DeedFilter';
import '../../../styles/pages/deeds.scss';
import { businessTypeEnum } from '../../../utils/enums/businessTypeEnum';
import { userRoles } from '../../../utils/enums/userRolesEnum';
import * as utils from '../../../utils/extensions';
import { DeedTabs } from './deedTabs';
import { languages } from '../../../common/const/resources';
import { DeedApi } from '../../../api/deed';
import { runtimeVariables } from '../../../globalEnvVariables';

export interface IProps {
	deed: Deed;
	companies: TradeCompany[];
	deedTypeSubTypes: DeedTypeSubType[];
	deedTypePrimaries: DeedType[];
	bodyTypes: BaseType[];
	positionTypes: BaseType[];
	peopleNames: Person[];
	notaryPeopleNames: NotaryPeople[];
	powers: BaseType[];
	powerTypeNames: BaseType[];
	language: Language;
	user: User;
	deedFilter: DeedFilter;
	business: BaseType[];
	loading: boolean;
	currentUser: UserState;

	saveDeed: (deed: Deed,Files:File[], language: Language, currentUser: UserState, deedPrevio: Deed) => void;
	getDeed: (id: number) => void;
	getCompanies: (tradeId?: number) => void;
	getDeedTypeSubTypes: () => void;
	getDeedTypePrimaries: () => void;
	getBodyTypeNames: () => void;
	getPositionTypes: () => void;
	getPeopleNames: (filter: string) => Promise<Person[]>;
	getNotaryPeopleNames: (filter: string) => Promise<NotaryPeople[]>;
	getPowers: () => void;
	getPowerTypes: () => void;
	getBusiness: () => Promise<BaseType[]>;
	updateNotaryData: (notaryData: NotaryData) => void;
}

export interface IState {
	deed: Deed;
	files: File[];
	selectedFile: File;
	initialFiles: DocumentFile[];
	errorMessages: string[];
	addressVisible: boolean;
	isReadOnly: boolean;
	isAuthorized: boolean;
	uniqueCode: SearchSelectModel;
	uniqueCodes: KeyValue[];
	hasMaxError: boolean;
	showDissolved: boolean;
	companyName: string;
	deedTypeName: string;
	subDeedTypeName: string;
	originalTypeName: string;
	negocioName: string;
	trazasDocumentos: string[];
}

export class DeedsForm extends React.Component<RouteComponentProps<{}> & IProps, IState> {
	constructor(props: any) {
		super(props);
		this.state = {
			deed:createEmptyDeed(),
			files: [],
			selectedFile: null,
			initialFiles: [],
			errorMessages: [],
			addressVisible: false,
			isReadOnly: false,
			uniqueCode: null,
			uniqueCodes: [],
			isAuthorized: true,
			hasMaxError: false,
			showDissolved: true,
			companyName: "",
			deedTypeName: "",
			subDeedTypeName: "",
			originalTypeName: "",
			negocioName: "",
			trazasDocumentos: []
		};

	}

	public componentDidMount() {
		window.scrollTo(0, 0);
		
		if(window.location.href.includes("new")){
			this.setState(({showDissolved: false}))
		}
		
		if (this.props.match.params['id'] != null) {
			if (this.props.match.path.includes('view')) {
				this.setState({ isReadOnly: true });
			}
			this.props.getDeed(this.props.match.params['id']);
		}

		this.props.getCompanies();
		this.props.getDeedTypePrimaries();
		this.props.getDeedTypeSubTypes();
		this.props.getBodyTypeNames();
		this.props.getPositionTypes();
		this.props.getPeopleNames();
		this.props.getNotaryPeopleNames();
		this.props.getPowers();
		this.props.getPowerTypes();
		this.props.getBusiness();
		this.props.getOriginals();

	}
	public componentWillReceiveProps(newProps) {
		let uniqueCode = this.state.uniqueCode;

		if (newProps.deed && newProps.deed !== this.props.deed) {
			let initialDocuments = newProps.deed.documentsSp ? newProps.deed.documentsSp.slice() : [];
			if (
				newProps.deed.deedType?.descriptionEnglish === Constants.ConstitutionDeed ||
				newProps.deed.deedType?.descriptionEnglish === Constants.AmendmentOfByLawsDeed ||
				newProps.deed.subDeedType?.descriptionEnglish === Constants.ConstitutionDeed ||
				newProps.deed.subDeedType?.descriptionEnglish === Constants.AmendmentOfByLawsDeed
			) {
				this.setState({ addressVisible: true });
			}
			let company: TradeCompany = this.props.companies.find((x) => x.id === parseInt(newProps.deed.companyId));
			if (company) {
				uniqueCode = { label: company.interlocutor, value: company.id };
			}

			let userIdRoles: Array<number>=[];
			let isAuthorized = true;

			this.props.currentUser.currentUser?.roles.forEach((userRole) => {
				let tempUserBusinessId = utils.getBusinessNewPowerByRole(userRole);
				userIdRoles.push(tempUserBusinessId);
			});			

			if (!userIdRoles.includes(0) && !userIdRoles.includes(newProps.deed.tradeId) && !this.state.isReadOnly) {
				isAuthorized = false;
			}			

			this.setState({
				deed: newProps.deed,
				initialFiles: initialDocuments,
				isAuthorized: isAuthorized,
				uniqueCode: uniqueCode,
			});

			//this.loadSelectedCompany();			
		}
	}

	public componentDidUpdate(prevProps, prevState) {
		if (this.props.companies && this.props.companies.length > 0 && prevProps.companies !== this.props.companies) {
			let uniqueCode = this.state.uniqueCode;

			let uniqueCodes: KeyValue[] = this.props.companies.map((company: TradeCompany) => {
				return { Key: company.id, Value: company.interlocutor };
			});

			if (this.props.deed?.companyId && this.props.match.params['id'] && !uniqueCode) {
				let company: TradeCompany = this.props.companies.find((x) => x.id === parseInt(this.props.deed.companyId));
				if (company) {
					uniqueCode = { label: company.interlocutor, value: company.id };

					//this.loadSelectedCompany();
				}
			}

			this.setState({
				uniqueCodes,
				uniqueCode,
			});
		}
	}

	private readonly getSelectedCompany = () : ISelectOption[] => {
		let selectedCompanies: ISelectOption[] = [];
		if (this.props.companies && this.props.companies.length > 0 && this.state.deed.companyId){
			let company: TradeCompany = this.props.companies.find((x) => x.id === this.state.deed.companyId);
			if(company){
				selectedCompanies.push( {value:company.id,label:company.name, disolutionDate: null, liquidationDate: null});
			}
		}

		return selectedCompanies;
	}

	private readonly loadCodeOptions = (inputValue, callback) => {
		if (inputValue.length >= 2) {
			setTimeout(() => {
				callback(this.filterCodes(inputValue));
			}, 1000);
		}
	};

	private readonly filterCodes = (inputValue: string): SearchSelectModel[] => {
		let options = this.state.uniqueCodes.filter((i: KeyValue) => i.Value.toLowerCase().includes(inputValue.toLowerCase()));
		return options.map((option) => {
			return { value: option.Key, label: option.Value };
		});
	};

	public render() {
		let bodyTypes: BaseType[] = this.props.bodyTypes ? this.props.bodyTypes : [];
		let positionTypes: BaseType[] = this.props.positionTypes ? this.props.positionTypes : [];
		let powers: BaseType[] = this.props.powers ? this.props.powers : [];
		let powerTypes: BaseType[] = this.props.powerTypeNames ? this.props.powerTypeNames : [];

		let language = utils.getResourcesFile(this.props.language);
		let calendarStrings: IDatePickerStrings = language['calendar'];
		let isReadOnly = this.state.isReadOnly;

		let originals: IComboBoxOption[] = [];
		if (this.props.originals) {
			this.props.originals.forEach((item) => {
				originals.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
		}

		let business: IComboBoxOption[] = [];
		if (this.props.business) {
			this.props.business.forEach((item) => {
				business.push({
					key: item.id,
					text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
				});
			});
		}
		let companiesCombo: IComboBoxOption[] = [];

		if(this.props.companies){
			this.props.companies.forEach((item) => {
				companiesCombo.push({ 
					key: item.id, 
					text: item.name,
					id: item.fechaDisolucion ? new Date(item.fechaDisolucion).toLocaleDateString() : null,
					title: item.fechaLiquidacion ? new Date(item.fechaLiquidacion).toLocaleDateString() : null, });
			});			
		}

		let deedTypesCombo: IComboBoxOption[] = [];
		this.props.deedTypePrimaries.forEach((item) => {
			deedTypesCombo.push({
				key: item.id,
				text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
			});
		});

		let deedTypeSubTypesCombo: IComboBoxOption[] = [];
		let deedType = this.props.deedTypeSubTypes.find((item) => item.id === this.state.deed.deedTypeId);
		if (deedType != null) {
			if (deedType.listSubTypes != null) {
				deedType.listSubTypes.forEach((item) => {
					deedTypeSubTypesCombo.push({
						key: item.id,
						text: this.props.language.name.includes(ActiveLanguage.English) ? item.descriptionEnglish : item.descriptionSpanish,
					});
				});
			}
		}

		let tabs = (
			<DeedTabs
				deed={this.state.deed}
				updateFormDeed={this.updateFormDeed}
				peopleNames={this.props.peopleNames}
				notaryPeopleNames={this.props.notaryPeopleNames}
				positionTypes={positionTypes}
				companies={this.props.companies}
				bodyTypes={bodyTypes}
				powers={powers}
				powerTypes={powerTypes}
				language={this.props.language}
				getPeopleNames={this.props.getPeopleNames}
				isReadOnly={this.state.isReadOnly}
				getNotaryPeopleNames={this.props.getNotaryPeopleNames}				
			/>
		);

		let documents: DocumentFile[] = this.state.initialFiles ? this.state.initialFiles : [];


		let validationErrors = [];
		this.state.errorMessages.forEach((item) => {
			validationErrors.push(<MessageBarComponent Message={item} Type={MessageBarType.error} MessageVisible={true} close={null} />);
		});

		let title = isReadOnly ? language['deeds']['form']['text-view'] : language['deeds']['form']['text-edit'];

		// const onRenderOption = (option: any): JSX.Element => {
		// 	if (option.itemType == DropdownMenuItemType.Header) {
		// 		if(option.key == 0){
		// 			return (
		// 				<Toggle 
		// 				inlineLabel 
		// 				label={language['SocietiesCombo']['showDissolved']} 
		// 				checked = {this.state.showDissolved}
		// 				onChange={(event, checked)=>{this.setState({showDissolved: checked})}} />
		// 			);
		// 		}
		// 		else{	
		// 			return (
		// 				<table>
		// 					<tbody>
		// 						<tr>
		// 							<td style={{ width: 15, fontSize: 14, padding: 5 }}></td>
		// 							<td style={{ width: 200, fontSize: 14, padding: 0 }}>{option.key}</td>
		// 							<td style={{ width: 100, fontSize: 14, padding: 0 }}>{option.text}</td>
		// 							<td style={{ width: 100, fontSize: 14, padding: '0px 10px' }}>{option.title}</td>

		// 						</tr>
		// 					</tbody>
		// 				</table>
		// 			);
		// 		}
		// 	} else {
		// 		return (
		// 			<table>
		// 				<tbody>
		// 					<tr>
		// 						<td style={{ width: 200, fontSize: 14, padding: 5 }}>{option.text}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.title}</td>
		// 						<td style={{ width: 100, fontSize: 14, padding: 5 }}>{option.id}</td>
		// 					</tr>
		// 				</tbody>
		// 			</table>
		// 		);
		// 	}
		// };

		let sortedCompanies = companiesCombo.sort((a, b) => a.text.localeCompare(b.text))
		// sortedCompanies.unshift({ key: language['SocietiesCombo']['name'], text: language['SocietiesCombo']['settlementDate'], title:language['SocietiesCombo']['dissolutionDate'], itemType: DropdownMenuItemType.Header });
		// sortedCompanies.unshift({ key: 0, text: "", itemType: DropdownMenuItemType.Header });	
		
		let filteredCompanies= (this.state.showDissolved) ? sortedCompanies : sortedCompanies.filter(x=> (x.title == null && x.id == null)|| x.title === language['SocietiesCombo']['dissolutionDate'])
		let options: ISelectOption[] = filteredCompanies.map(company => {
			return {
				value:company.key.toString(),
				label:company.text, 
				disolutionDate:company.id, 
				liquidationDate: company.title
			};
		  });	
		  let selectedCompanies=this.getSelectedCompany();	

		return (
			<>
				{!this.state.isAuthorized && <UnauthorizedView language={this.props.language} />}
				{this.state.isAuthorized && (
					<>
						<SpinnerContainer message='Cargando...' />
						<div className='main-container__pageTitle'>
							<h1>
								{this.props.match.params['id'] == null ? language['deeds']['form']['text-add'] : title}
								{language['deeds']['form']['title']}
							</h1>
						</div>
						<div className='ms-Grid'>
							<div className='ms-Grid-row flex-option'>
								<div className='ms-Grid-col ms-xl9'>
									<p className='subtitle'>{language['deeds']['form']['general-data']}</p>
								</div>
								<div className='ms-Grid-col ms-xl3'>
									<p className='subtitle'>{language['deeds']['form']['documentation']}</p>
								</div>
							</div>
							<div className='ms-Grid-row flex-option new_deed'>
								<div className='ms-Grid-col ms-xl3 content_edit_block_filter'>
									<p>{language['deeds']['form']['fields']['trade']['title']}</p>
									<ComboBox
										allowFreeform
										autoComplete='on'
										options={business.sort((a, b) => a.text.localeCompare(b.text))}
										placeholder={language['authorizations']['list']['filter']['options']['trade']['placeholder']}
										onChange={this.handleTradeChange}
										selectedKey={this.state.deed.tradeId}
										useComboBoxAsMenuWidth
										disabled={isReadOnly}
									/>
									<p>{language['deeds']['form']['fields']['society']['title']}</p>
									{/* <ComboBox
										autoComplete='on'
										allowFreeform={true}
										options={this.state.showDissolved ? sortedCompanies : sortedCompanies.filter(x=> (x.title == null && x.id == null)|| x.title == language['SocietiesCombo']['dissolutionDate'])}
										placeholder={language['deeds']['form']['fields']['society']['placeholder']}
										onChange={this.handleCompanyChange}
										onRenderOption={onRenderOption}
										selectedKey={this.state.deed.companyId}
										disabled={isReadOnly}
									/>							 */}
									<CustomSelect
										options={options}
										handleCustomSelectChange={this.handleReactSelectCompanyChange}
										showDissolved={this.state.showDissolved}
										handleShowDissolvedChange={(event, checked)=>{this.setState({showDissolved: checked})}}
										language={language}
										showDissolutionDate={false}
										selectedValues={selectedCompanies}
										isMultiSelection={false}
										isDisabled={isReadOnly}
										placeholder={language['deeds']['form']['fields']['society']['placeholder']}
									/>											
									<p>{language['deeds']['form']['fields']['uniqueCode']['title']}</p>
									<SearchPeople
										title={''}
										placeholder={language['representatives']['form']['fields']['uniqueCode']['placeholder']}
										language={this.props.language}
										selectedOption={this.state.uniqueCode}
										loadOptions={this.loadCodeOptions}
										handleNameChange={this.handleUniqueCodeChange}
										getUsers={this.props.getPeopleNames}
										disabled={isReadOnly}
										onClearValue={this.onClearSearchPeople}
									/>									
									<p>{language['deeds']['form']['fields']['document-date']['title']}</p>
									<DatePicker
										formatDate={(newDate: Date) => utils.formatDate(newDate, this.props.language)}
										firstDayOfWeek={this.props.language.name.includes(ActiveLanguage.English) ? DayOfWeek.Sunday : DayOfWeek.Monday}
										className={''}
										allowTextInput={true}
										parseDateFromString={(newDate: string) => language == languages.languageES ? utils.formatDateInputText(newDate) : new Date(newDate)}
										placeholder={language['generic']['date']}
										strings={calendarStrings}
										value={utils.getParsedDate(this.state.deed.documentDate)}
										onSelectDate={this.handleDocumentDateChange}
										disabled={isReadOnly}
									/>	
									<span style={{ display: this.state.addressVisible ? '' : 'none' }}>
										<p>{language['deeds']['form']['fields']['social-address']['title']}</p>
										<TextField
											type='text'
											value = {utils.isNullOrEmpty(this.state.deed.socialAddress) ? '' : this.state.deed.socialAddress}
											onChange={this.handleSocialAddressChange}
											onBlur={this.handleSocialAddressBlur}
											disabled={isReadOnly}
											maxLength={150}
										/>
									</span>																																					
								</div>
								<div className='ms-Grid-col ms-xl3 content_edit_block_filter'>
									<p>{language['deeds']['form']['fields']['deed-type']['title']}</p>
									<ComboBox
										allowFreeform
										autoComplete='on'
										options={deedTypesCombo.sort((a, b) => a.text.localeCompare(b.text))}
										placeholder={language['deeds']['form']['fields']['deed-type']['placeholder']}
										onChange={this.handleDeedTypeChange}
										selectedKey={this.state.deed.deedTypeId}
										useComboBoxAsMenuWidth
										disabled={isReadOnly}
									/>
									<p>{language['deeds']['form']['fields']['deed-subtype']['title']}</p>
									<ComboBox
										allowFreeform
										autoComplete='on'
										options={deedTypeSubTypesCombo.sort((a, b) => a.text.localeCompare(b.text))}
										placeholder={language['deeds']['form']['fields']['deed-subtype']['placeholder']}
										onChange={this.handleDeedSubTypeChange}
										selectedKey={this.state.deed.subDeedTypeId}
										useComboBoxAsMenuWidth
										disabled={isReadOnly}
									/>
									<p>{language['deeds']['form']['sections']['notarial-data']['fields']['protocol']['title']}</p>
									<TextField
										type='text'
										className=' content_edit_block_filter-inner'
										value = {utils.isNullOrEmpty(this.state.deed.notaryData?.protocolNumber) ? '' : this.state.deed.notaryData?.protocolNumber}
										onChange={this.handleProtocolNumberChange}
										onBlur={this.handleProtocolNumberBlur}
										disabled={this.props.isReadOnly}
										maxLength={50}
									/>							
									<p>{language['deeds']['form']['fields']['location']['title']}</p>
									<TextField
										type='text'
										value = {utils.isNullOrEmpty(this.state.deed.locationDeed) ? '' : this.state.deed.locationDeed}
										onChange={this.handleLocationChange}
										onBlur={this.handleLocationBlur}
										disabled={isReadOnly}
										maxLength={255}
									/>
								</div>	
								<div className='ms-Grid-col ms-xl3 content_edit_block_filter'>	
								<p>{language['deeds']['form']['fields']['original']['title']}</p>
									<ComboBox
										allowFreeform
										autoComplete='on'
										options={originals.sort((a, b) => a.text.localeCompare(b.text))}
										placeholder={language['deeds']['form']['fields']['original']['placeholder']}
										onChange={this.handleOriginalTypeChange}
										selectedKey={this.state.deed.originalTypeId}
										useComboBoxAsMenuWidth
										disabled={isReadOnly}
									/>
									<p>{language['deeds']['form']['fields']['copy']['title']}</p>
									<TextField
										type='text'
										value = {utils.isNullOrEmpty(this.state.deed.copy) ? '' : this.state.deed.copy}
										onChange={this.handleCopyChange}
										onBlur={this.handleCopyBlur}
										disabled={isReadOnly}
										maxLength={255}
									/>
									<p>{language['deeds']['form']['fields']['simple']['title']}</p>
									<TextField
										type='text'
										value = {utils.isNullOrEmpty(this.state.deed.simple) ? '' : this.state.deed.simple}
										onChange={this.handleSimpleChange}
										onBlur={this.handleSimpleBlur}
										disabled={isReadOnly}
										maxLength={255}
									/>	
									<p>{language['deeds']['form']['fields']['subject']['title']}</p>
									<TextField
										value = {utils.isNullOrEmpty(this.state.deed.subject) ? '' : this.state.deed.subject}
										onChange={this.handleSubjectChange}
										onBlur={this.handleSubjectBlur}
										disabled={isReadOnly}
										maxLength={1000}
										multiline 										
									/>																																												
								</div>
								<div className='ms-Grid-col ms-xl3 content_edit_block_filter'>
									<span style={{ display: isReadOnly ? 'none' : '' }}>
										<p>{language['deeds']['form']['fields']['documents']['title']}</p>
										<Dropzone className='dropzone' onDrop={this.onDrop}>
											<i className='ms-Icon ms-Icon--CloudUpload' aria-hidden='true'></i>
											<p className='dropzone-title'>{language['generic']['form']['dropzone']['title']}</p>
											<p className='dropzone-maxsize'>{language['generic']['form']['dropzone']['max-size']}</p>
											<span className='custom-file-upload'>
												<input type='file' onChange={this.uploadFile} />
												<Icon iconName='CloudUpload'></Icon>{' '}
												{language['generic']['form']['uploadDocument']['title']}
											</span>
										</Dropzone>
									</span>
									<ul className='ms-Grid-col ul-documents'>
										{this.state.hasMaxError ? (
											<MessageBarComponent
												ClassName='fixMsgMaxError'
												Message={language['generic']['form']['dropzone']['max-size']}
												Type={MessageBarType.error}
												MessageVisible={true}
												close={null}
											/>
										) : (
											this.state.files.map((f) => (
												<li key={f.name}>
													{f.name}
													<span className='delete-files-icon' onClick={() => this.handleDeselect(f)}>
														&times;
													</span>
												</li>
											))
										)}
										{documents.map((item) => (
											<li key={item.fileName}>
												{/* <a
													href={runtimeVariables._instance.REACT_APP_SHAREPOINT + item.documentLink}
													target='_blank'
													rel='noopener noreferrer'
												>
													{item.fileName}
												</a> */}
												<span
													/*onClick={() => window.open(runtimeVariables._instance.REACT_APP_SHAREPOINT + item.documentLink, "_blank")}*/
													onClick={() => {
														const urltemp = runtimeVariables._instance.REACT_APP_SHAREPOINT + item.documentLink;
														const url = urltemp.replace(/ /g, "%20");
														if (utils.isURLValid(url)){
															window.open(url, "_blank")}
															//else{console.log(url)}
																	}
															}
													className='url-style'
												>
													{item.fileName}
												</span>
												<span
													className='delete-files-icon'
													onClick={() => this.handleDeleteFileSp(item)}
													style={{ display: isReadOnly ? 'none' : '' }}
												>
													&times;
												</span>
											</li>
										))}
									</ul>
								</div>
							</div>
							{validationErrors}
							{tabs}
							<div className='ms-Grid-row'>
								<hr />
								<button
									type='button'
									name='button'
									className={`button-primary ${!this.props.loading ? '' : 'isDisabled'} `}
									style={{ display: isReadOnly ? 'none' : '' }}
									onClick={this.handleSaveDeed}
								>
									{language['deeds']['form']['save-button']}
								</button>
								<button type='button' name='button' className={`button-cancel`} onClick={this.handleCancel}>
									{language['deeds']['form']['cancel-button']}
								</button>
							</div>
						</div>
					</>
				)}
			</>
		);
	}

	private readonly onClearSearchPeople = (): void => {
		let uniqueCode = { ...this.state.uniqueCode };
		uniqueCode.value = null;
		uniqueCode.label = null;
		this.setState({ uniqueCode: uniqueCode });
	};	

	private readonly validateDeed = (deed: Deed): boolean => {
		let validationMessages = [];
		let language = utils.getResourcesFile(this.props.language);
		let isValidCompany = deed.companyId !== null && deed.companyId > 0;
		let isValidTrade = deed.tradeId !== null && deed.tradeId > 0;
		let isValidDeedType = deed.deedTypeId !== null && deed.deedTypeId > 0;
		let isValidDocumentDate = deed.documentDate !== null;
		//let isValidDocuments = deed.documentsSp?.length > 0 || this.state.files.length > 0;
		let isValidSocialAddress = this.state.addressVisible ? deed.socialAddress !== null && deed.socialAddress?.length > 0 : true;

		let isDeedValid =
			isValidCompany &&
			isValidDeedType &&
			isValidDocumentDate &&
			isValidTrade &&
			isValidSocialAddress;  // && isValidDocuments

		if (!isValidTrade) {
			validationMessages.push(language['generic']['messages']['required-trade']);
		}
		if (!isValidCompany) {
			validationMessages.push(language['generic']['messages']['required-society']);
		}
		if (!isValidDeedType) {
			validationMessages.push(language['generic']['messages']['required-deedType']);
		}
		if (!isValidSocialAddress) {
			validationMessages.push(language['generic']['messages']['required-social-address']);
		}
		if (!isValidDocumentDate) {
			validationMessages.push(language['generic']['messages']['required-document-date']);
		}
		/*if (!isValidDocuments) {
			validationMessages.push(language['generic']['messages']['required-document']);
		}*/
		if (!isDeedValid) {
			this.setState({ errorMessages: validationMessages });
		}

		return isDeedValid;
	};

	private readonly updateFormDeed = (deed: Deed) => {
		this.setState({ deed: deed });
	};

	public readonly handleCancel = () => {
		this.props.history.goBack();
	};

	private readonly handleSimpleChange = (value): void => {
		let deed = { ...this.state.deed };
		deed.simple = value.target.value;
		this.setState({ deed: deed });
	};
	private readonly handleSimpleBlur = (value): void => {
		let deed = { ...this.state.deed };
		deed.simple = value.target.value.toUpperCase();
		this.setState({ deed: deed });
	};	

	private readonly handleCopyChange = (value): void => {
		let deed = { ...this.state.deed };
		deed.copy = value.target.value;
		this.setState({ deed: deed });
	};
	private readonly handleCopyBlur = (value): void => {
		let deed = { ...this.state.deed };
		deed.copy = value.target.value.toUpperCase();
		this.setState({ deed: deed });
	};	

	private readonly handleOriginalTypeChange = (event, option): void => {
		let deed = { ...this.state.deed };
		deed.originalTypeId = parseInt(option?.key);
		this.setState({ deed: deed, originalTypeName: option.text });
	};

	// private readonly handleCompanyChange = (event, option): void => {
	// 	let deed = { ...this.state.deed };
	// 	deed.companyId = parseInt(option?.key);
	// 	let company: TradeCompany = this.props.companies.find((x) => x.id === parseInt(option?.key));
	// 	let tradeId = company?.tradeId;
	// 	deed.tradeId = tradeId;
	// 	this.setState({
	// 		deed: deed,
	// 		uniqueCode: company ? { value: company.id, label: company.interlocutor } : null,
	// 	});
	// };

	private readonly handleReactSelectCompanyChange = (selectedOption) => {

		let deed = { ...this.state.deed };

		//let selectedCompanies: ISelectOption[];    Clean Warning
		let company: TradeCompany = null;

		if(selectedOption!==null && selectedOption.value !== null){
			//TODO: confirmar por qué el operador estricto no consigo que funcione
			company = this.props.companies.find((x: TradeCompany) => x.id === (Number(selectedOption?.value)));
		}	

		deed.companyId = company?.id;
		let tradeId = company?.tradeId;
		deed.tradeId = tradeId;
		this.setState({
			deed: deed,
			uniqueCode: company ? { value: company.id, label: company.interlocutor } : null,
			companyName: selectedOption.label
		});
	}

	private readonly handleUniqueCodeChange = (uniqueCode: SearchSelectModel) => {
		let deed = { ...this.state.deed };
		deed.companyId = +uniqueCode.value;
		let tradeId = this.props.companies.find((x) => x.id === deed.companyId)?.tradeId;
		deed.tradeId = tradeId;
		this.setState({ deed: deed, uniqueCode: uniqueCode });
	};

	private readonly handleTradeChange = (event, option): void => {
		let deed = { ...this.state.deed };
		deed.tradeId = parseInt(option?.key);
		deed.companyId = 0;
		this.props.getCompanies(deed.tradeId);
		this.setState({ deed: deed, uniqueCode: null, negocioName: option.text });
	};

	private readonly handleDeedTypeChange = (event, option): void => {
		let deed = { ...this.state.deed };

		let constitutionID = this.props.deedTypePrimaries.find((x) => x.descriptionEnglish === Constants.ConstitutionDeed)?.id;
		let amendmentBylaws = this.props.deedTypePrimaries.find((x) => x.descriptionEnglish === Constants.AmendmentOfByLawsDeed)?.id;

		deed.deedTypeId = parseInt(option?.key);
		deed.subDeedTypeId = deed.deedTypeId === 0 ? 0 : null;
		let addressVisible = deed.deedTypeId === constitutionID || deed.deedTypeId === amendmentBylaws ? true : false;
		this.setState({ deed: deed, addressVisible: addressVisible, deedTypeName: option.text });
	};

	private readonly handleDeedSubTypeChange = (event, option): void => {
		let deed = { ...this.state.deed };

		deed.subDeedTypeId = parseInt(option?.key);
		this.setState({ deed: deed, subDeedTypeName: option.text });
	};

	private readonly handleSubjectChange = (value): void => {
		let deed = { ...this.state.deed };
		deed.subject = value.target.value;
		this.setState({ deed: deed });
	};
	private readonly handleSubjectBlur = (value): void => {
		let deed = { ...this.state.deed };
		deed.subject = value.target.value.toUpperCase();
		this.setState({ deed: deed });
	};

	private readonly handleSocialAddressChange = (value): void => {
		let deed = { ...this.state.deed };
		deed.socialAddress = value.target.value;
		this.setState({ deed: deed });
	};
	private readonly handleSocialAddressBlur = (value): void => {
		let deed = { ...this.state.deed };
		deed.socialAddress = value.target.value.toUpperCase();
		this.setState({ deed: deed });
	};

	public readonly handleDocumentDateChange = (value): void => {
		let deed = { ...this.state.deed };
		deed.documentDate = value;
		this.setState({ deed: deed });
	};

	private readonly handleSaveDeed = (): void => {
		let deed = { ...this.state.deed };

		if (this.validateDeed(deed)) {
			if(deed.id !== 0){
				let trazasMiembros:string[] = []
				let trazasAccionistas:string[] = []
				let cambiosEscritura: any = utils.getChangedFields(this.props.deed, deed);
				let cambiosNotaryData: any = utils.getChangedFields(this.props.deed.notaryData, deed.notaryData);
				let cambiosRecordData: any = utils.getChangedFields(this.props.deed.recordData, deed.recordData);
				let cambiosSocialData: any = utils.getChangedFields(this.props.deed.socialCapital, deed.socialCapital);

				if(Object.keys(cambiosNotaryData).length > 0 ){
					cambiosEscritura.DatosNotariales = utils.objectToString(cambiosNotaryData);
				}

				if(Object.keys(cambiosRecordData).length > 0){
					cambiosEscritura.DatosRegistrales = utils.objectToString(cambiosRecordData);
				}

				if(Object.keys(cambiosSocialData).length > 0){
					cambiosEscritura.DatosRegistrales = utils.objectToString(cambiosSocialData);
				}

				if(this.props.deed.notaryData.protocolNumber !== deed.notaryData.protocolNumber){
					cambiosEscritura.NumeroProtocolo =  deed.notaryData.protocolNumber;
				}
				if(this.props.deed.documentDate !== deed.documentDate){
					cambiosEscritura.FechaDocumento = utils.formatDate(deed.documentDate)
				}

				if(cambiosEscritura.companyId){
					delete cambiosEscritura.companyId;
					cambiosEscritura.Compania = this.state.companyName;
				}

				if(this.state.deedTypeName !== ""){
					delete cambiosEscritura.deedTypeId;
					cambiosEscritura.TipoEscritura = this.state.deedTypeName;
				}

				if(this.state.subDeedTypeName !== ""){
					delete cambiosEscritura.subDeedTypeId;
					cambiosEscritura.SubtipoEscritura = this.state.subDeedTypeName;
				}

				if(this.state.negocioName !== ""){
					delete cambiosEscritura.tradeId;
					cambiosEscritura.Negocio = this.state.companyName;
				}

				if(this.state.originalTypeName !== ""){
					delete cambiosEscritura.originalTypeId;
					cambiosEscritura.Original = this.state.originalTypeName
				}


				if(deed.members.length > 0){
					deed.members.forEach((member)=>{
						if(member.trazas){
							trazasMiembros.push(member.trazas)
						}
						//hemos de borrar la propiedad trazas porque el back no espera que se las mandemos
						//pero las necesitamos para poder construir el objeto de trazas de SOX
						delete member.trazas;
					})
				}

				if(deed.stockholders.length > 0){
					deed.stockholders.forEach((member)=>{
						if(member.trazas){
							trazasAccionistas.push(member.trazas)
						}
						//hemos de borrar la propiedad trazas porque el back no espera que se las mandemos
						//pero las necesitamos para poder construir el objeto de trazas de SOX
						delete member.trazas;
					})
				}

				const DeedMemberId = deed.members.map((representative)=> representative.id)
				const DeedMemberIdPrev = this.props.deed.members.length > 0 ? this.props.deed.members.map((representative)=> representative.id): [] ;
				const DeedMembersDeleted = utils.findRemovedValuesFromArrays(DeedMemberIdPrev, DeedMemberId);
				if(DeedMembersDeleted.length > 0){
					trazasMiembros.push(`Se han borrado los organos de administración con id: ${DeedMembersDeleted}`);
				}

				const DeedStockholderId = deed.members.map((representative)=> representative.id);
				const DeedStockholderIdPrev = this.props.deed.members.length > 0 ? this.props.deed.members.map((representative)=> representative.id) : [] ;
				const DeedStockholderDeleted = utils.findRemovedValuesFromArrays(DeedStockholderIdPrev, DeedStockholderId);
				if(DeedMembersDeleted.length > 0){
					trazasAccionistas.push(`Se han borrado los capitalistas con id: ${DeedStockholderDeleted}`);
				}

				if(trazasMiembros.length> 0){
					cambiosEscritura.OrganosAdministrativos = trazasMiembros;
				}

				if(trazasAccionistas.length > 0){
					cambiosEscritura.Accionistas = trazasAccionistas;
				}

				if(this.state.trazasDocumentos.length > 0){
					cambiosEscritura.documentos = this.state.trazasDocumentos;
					cambiosEscritura.documentos.forEach((documento)=>{
						documento = documento.replace(",","\n");
					})
				}

				let ObjetoEnviarTrazas = `${utils.objectToString(cambiosEscritura)}`

				ObjetoEnviarTrazas.replace(",","\n");


				this.props.saveDeed(deed,this.state.files, this.props.language, this.props.currentUser, ObjetoEnviarTrazas);

			}else{
				let trazasMiembros:string[] = []
				let trazasAccionistas: string[] = [];
					if(deed.members.length > 0){
						deed.members.forEach((miembro)=>{
							if(miembro.trazas){
								trazasMiembros.push(miembro.trazas)
							}
							//hemos de borrar la propiedad trazas porque el back no espera que se las mandemos
							//pero las necesitamos para poder construir el objeto de trazas de SOX
							delete miembro.trazas;
						})
					}

					if(deed.stockholders.length > 0){
						deed.stockholders.forEach((miembro)=>{
							if(miembro.trazas){
								trazasAccionistas.push(miembro.trazas)
							}
							//hemos de borrar la propiedad trazas porque el back no espera que se las mandemos
							//pero las necesitamos para poder construir el objeto de trazas de SOX
							delete miembro.trazas;
						})
					}


					let ObjetoEnviarTrazas: any = {
						Sociedad: this.state.companyName,
						TipoEscritura: this.state.deedTypeName,
						Asunto: deed.subject,
						FechaDocumento: utils.formatDate(deed.documentDate),
						OrganosAdministracion: trazasMiembros,
						Accionistas: trazasAccionistas,
						Negocio: this.state.negocioName,
						SubTipoEscritura: this.state.subDeedTypeName,
						Original: this.state.originalTypeName,
						Localizacion: deed.locationDeed,
						NumeroProtocolo: deed.notaryData.protocolNumber
					}

					if(this.state.trazasDocumentos.length > 0){
						ObjetoEnviarTrazas.Documentos = this.state.trazasDocumentos
					}

					if(deed.notaryData.notaryPeopleName){
						let objetoNotaria: any = {}
						objetoNotaria.Notario = deed.notaryData.notaryPeopleName
						objetoNotaria.Acta = deed.notaryData.notary
						objetoNotaria.Pais = deed.notaryData.city
						ObjetoEnviarTrazas.DatosNotariales = utils.limpiarObjeto(objetoNotaria)
					}

					if(deed.recordData){
						let objetoRegistro: any = {}
						objetoRegistro.RegistroMercantil = deed.recordData.merchandRecord
						objetoRegistro.Folio = deed.recordData.folio
						objetoRegistro.Seccion = deed.recordData.section
						objetoRegistro.Inscripcion = deed.recordData.inscription
						objetoRegistro.FechaInscripcion = deed.recordData.inscriptionDate
						objetoRegistro.Hoja = deed.recordData.sheet
						objetoRegistro.Tomo = deed.recordData.volume

						ObjetoEnviarTrazas.DatosNotariales = utils.limpiarObjeto(objetoRegistro)

					}

					if(deed.socialCapital){
						let objetoCapitalSocial: any = {};
						objetoCapitalSocial.Nominal = deed.socialCapital.nominal;
						objetoCapitalSocial.Desembolso = deed.socialCapital.disbursement;
						objetoCapitalSocial.CapitalSocial = deed.socialCapital.initialSocialCapital;
						objetoCapitalSocial.TotalAcciones = deed.socialCapital.totalNumberStock;
						objetoCapitalSocial.UltimaNumeracion = deed.socialCapital.lastNumbering;
						objetoCapitalSocial.AccionesAmortizadas = deed.socialCapital.amortizedStock;
						objetoCapitalSocial.AccionesEmitidas = deed.socialCapital.issuedStock;
						objetoCapitalSocial.ImporteAmpliado = deed.socialCapital.extendedAmountType
						
						ObjetoEnviarTrazas.DatosNotariales = utils.limpiarObjeto(objetoCapitalSocial)

					}

					ObjetoEnviarTrazas = utils.objectToString(ObjetoEnviarTrazas);

				this.props.saveDeed(deed,this.state.files, this.props.language, this.props.currentUser, ObjetoEnviarTrazas);
			}
		}
	};

	public readonly onDrop = (files: File[]) => {
		let documentsFiles: DocumentFile[] = [];
		let hasMaxError: boolean = false;

		files.forEach((file) => {
			if (file.size > Constants.MinSizeFile && file.size < Constants.MaxSizeFile) {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				let document: DocumentFile = {
					fileName: file.name,
					contents: '',
					deedMetadata: null,
					recordMetadata: null,
					corporateLitigationMetadata: null,
					documentationMetadata: null,
					powerMetadata: null
				};
				
				/*
				reader.onloadend = function () {
					document.contents = (reader.result as string).split(',').pop();
				};*/

				let trazasDocumentos = [...this.state.trazasDocumentos];
				trazasDocumentos.push(`Se ha añadido el documento con el nombre: ${document.fileName}`)
				this.setState({trazasDocumentos: trazasDocumentos});

				documentsFiles.push(document);
			} else {
				hasMaxError = true;
			}
		});

		let state = { ...this.state };
		if (state.deed.documentsSp !== null && !hasMaxError) {
			state.deed.documentsSp = state.deed.documentsSp.concat(documentsFiles);
		}
		if (state.files != null && !hasMaxError) {
			state.files = state.files.concat(files);
			this.setState(state);
		}
		this.setState({ hasMaxError });
	};

	public readonly uploadFile = (event: any) => {
		let hasMaxError: boolean = false;
		let documentsFiles: DocumentFile[] = [];
		let file = event.target.files[0];

		if (file != null && file.size > Constants.MinSizeFile && file.size < Constants.MaxSizeFile) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			let document: DocumentFile = {
				fileName: file.name,
				contents: '',
				deedMetadata: null,
				recordMetadata: null,
				corporateLitigationMetadata: null,
				documentationMetadata: null,
			};
			/*
			reader.onloadend = function () {
				document.contents = (reader.result as string).split(',').pop();
			};
			*/
			documentsFiles.push(document);
		} else {
			hasMaxError = true;
		}

		let state = { ...this.state };
		if (state.deed.documentsSp !== null && !hasMaxError) {
			state.deed.documentsSp = state.deed.documentsSp.concat(documentsFiles);
		}
		if (state.files != null && !hasMaxError) {
			state.files = state.files.concat(file);
			this.setState(state);
		}
		this.setState({ hasMaxError });
	};

	public readonly handleDeselect = (file: File) => {
		let state = { ...this.state };
		let fileIndex = state.files.findIndex((item) => item === file);
		state.files.splice(fileIndex, 1);

		let documentIndex = state.deed.documentsSp.findIndex((item) => item.fileName === file.name);
		state.deed.documentsSp.splice(documentIndex, 1);
		this.setState(state);
	};

	public readonly handleDeleteFileSp = (file: DocumentFile): void => {
		let state = { ...this.state };
		let fileIndex = state.initialFiles.findIndex((item) => item === file);
		state.initialFiles.splice(fileIndex, 1);

		let fileIndex2 = state.deed.documentsSp.findIndex((item) => item === file);
		state.deed.documentsSp.splice(fileIndex2, 1);

		state.deed.deleteSPFiles.push(file.documentLink);
		state.trazasDocumentos.push(`Se ha quitado el documento con el nombre: ${file.fileName}`);
		this.setState(state);
	};

	private readonly handleProtocolNumberChange = (value) => {
		let deed = { ...this.state.deed };
		deed.notaryData.protocolNumber = value.target.value;
		this.setState({ deed });
	};
	private readonly handleProtocolNumberBlur = (value) => {
		let deed = { ...this.state.deed };
		deed.notaryData.protocolNumber = value.target.value.toUpperCase();
		this.setState({ deed });
	};
	private readonly handleLocationChange = (value): void => {
		let deed = { ...this.state.deed };
		deed.locationDeed = value.target.value;
		this.setState({ deed: deed });
	};
	private readonly handleLocationBlur = (value): void => {
		let deed = { ...this.state.deed };
		deed.locationDeed = value.target.value.toUpperCase();
		this.setState({ deed: deed });
	};
	private getTradeIdRole(): number {
		switch (this.props.currentUser.currentUser?.roles[0]) {
			case userRoles.ajAeropuertos:
			case userRoles.ajAeropuertosH:
				return businessTypeEnum.aeropuertos;
			case userRoles.ajCintra:
			case userRoles.ajCintraH:
				return businessTypeEnum.cintra;
			case userRoles.ajConstruccion:
			case userRoles.ajConstruccionH:
				return businessTypeEnum.construccion;
			case userRoles.ajCorporacion:
			case userRoles.ajCorporacionH:
				return businessTypeEnum.corporacion;
			case userRoles.ajFeim:
			case userRoles.ajFeimH:
				return businessTypeEnum.feim;
			case userRoles.admin:
				return 0;
		}
	}
}
