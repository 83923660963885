import { GenericApi, SendTraceToBBDD } from '../../../../api/generic';
import { actionTypes } from '../../../../common/const/actionTypes';
import { ApiUrls } from '../../../../common/const/api';
import { httpCallStartAction } from '../../../../common/middleware/http/actions';
import useHistory from '../../../../history';
import { UserState } from '../../../../model';
import { compareObjects, getResourcesFile, objectToString, showError, showSuccess } from '../../../../utils/extensions';
import { Language } from './../../../../model/Language';
import { Power } from './../../../../model/Powers/Power';
const browserHistory = useHistory;

export const savePowerAction = (power: Power, Files:File[], currentLanguage: Language,currentUser: UserState ,ObjetoTrazas?: string) => (dispatch) => {
	dispatch(httpCallStartAction());
	let language = getResourcesFile(currentLanguage);
	handleCreatePower(power,Files, language, dispatch, currentUser ,ObjetoTrazas);
};

const handleCreatePower =  (power: Power, Files:File[], language: any, dispatch, currentUser:UserState ,ObjetoTrazas?: string) => {

	const formData = new FormData();

	const encodedString = Buffer.from(JSON.stringify(power)).toString('base64');

	formData.append("UpsertPowerDto", encodedString);
	Files?.map((attachment) => {
		formData.append("Files", attachment);
	});

	if (power.id != null && power.id > 0) {
		GenericApi.UpdateAsyncFormData(ApiUrls.Powers, formData).then((result) => {
			if (result > 0) {
				showSuccess(language['representatives']['form']['messages']['success-update']);

				const trazaLogin = {
					Usuario: currentUser.currentUser.email,
					Grupos: currentUser.currentUser.roles.join(','),
					Accion: "Update Power",
					Fecha: new Date().toDateString(),
					Traza: `Se ha modificado el poder ${power.id}, con los siguientes cambios: ${ObjetoTrazas}`,
					IdRegistro: power.id.toString()
				}
				
				dispatch(savePowerCompleted(result));
				SendTraceToBBDD(trazaLogin);
				browserHistory.goBack();
			} else {
				showError(language['representatives']['form']['messages']['error-update']);
			}
		});
	} else {
		GenericApi.AddAsyncFormData(ApiUrls.Powers, formData).then((result) => {
			if (result > 0) {
				showSuccess(language['representatives']['form']['messages']['success']);
				power.id = result;
				const trazaLogin = {
					Usuario: currentUser.currentUser.email,
					Grupos: currentUser.currentUser.roles.join(','),
					Accion: "Create Power",
					Fecha: new Date().toDateString(),
					Traza: `Se ha generado el poder con id: ${result} y los siguientes parámetros: ${ObjetoTrazas}`,
					IdRegistro: power.id.toString(),
				}
				dispatch(savePowerCompleted(result));
				SendTraceToBBDD(trazaLogin);

				browserHistory.goBack();
			} else {
				showError(language['representatives']['form']['messages']['error']);
			}
		});
	}
};

const savePowerCompleted = (result: number) => ({
	type: actionTypes.SAVE_POWER_COMPLETED,
	payload: result,
	meta: {
		httpEnd: true,
	},
});
